import React, { FC } from 'react';

import { formatMoney } from '../../../utils/formatMoney';

import ProgressBar from '../../UI/ProgressBar/ProgressBar';
import Typography from '../../UI/Typography/Typography';
import Stack from '../../UI/Stack/Stack';

import * as styles from './CharityCampaign.module.scss';

import InfoIcon from '../../../images/icons/info.svg';

export interface CharityCampaignProps {
    title: string;
    money: {
        raised: number;
        goal: number;
    };
}

const CharityCampaign: FC<CharityCampaignProps> = ({ title, money }) => {
    const raisedMoney = formatMoney(money.raised, 0);
    const goalMoney = formatMoney(money.goal, 0);
    const percentage = (money.raised * 100) / money.goal;

    return (
        <Stack className={styles.root} spacing={11} fullWidth align="normal">
            <Stack direction="row" spacing={8} valign="center">
                <InfoIcon
                    className={styles.infoIcon}
                    alt="info-icon"
                    width={16}
                    height={16}
                />
                <Typography className={styles.title} title={title}>
                    {title}
                </Typography>
            </Stack>
            <Typography align="right" paragraph variant="bodySmall">
                £{raisedMoney} out of £{goalMoney}
            </Typography>
            <ProgressBar value={percentage} />
        </Stack>
    );
};

export default CharityCampaign;
