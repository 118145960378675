import React, { FC } from 'react';

import SearchIcon from '../../../images/icons/search.svg';

import * as styles from './SearchInput.module.scss';

export interface SearchInputProps
    extends Omit<React.HTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
    value: string;
    onChange: (newValue: string) => void;
}

const SearchInput: FC<SearchInputProps> = ({
    value,
    onChange,
    ...restProps
}) => {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        onChange(e.target.value);
    };

    return (
        <div className={styles.root}>
            <input
                className={styles.input}
                type="text"
                value={value}
                onChange={handleChange}
                {...restProps}
            />
            <SearchIcon
                className={styles.icon}
                alt="search icon"
                width={24}
                height={24}
            />
        </div>
    );
};

export default SearchInput;
