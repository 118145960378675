import React from 'react';
import { useStaticQuery, graphql, GetServerDataReturn } from 'gatsby';

import SEO from '../../components/layout/SEO';
import Layout from '../../components/layout/Layout/Layout';
import Section from '../../components/UI/Section/Section';
import Container from '../../components/UI/Container/Container';
import InfoBlock from '../../components/pages/charities/InfoBlock/InfoBlock';
import CharitiesBlock from '../../components/pages/charities/CharitiesBlock/CharitiesBlock';
import Spinner from '../../components/spinner/Spinner';

import { getCharities } from '../../api/charity/getCharities';
import { Charity } from '../../../types/global';

import * as styles from '../../components/pages/charities/index.module.scss';

type ServerProps = {
    charities: Charity[];
};

export async function getServerData(): GetServerDataReturn<ServerProps> {
    const charities = await getCharities();

    return {
        props: { charities },
    };
}

type ServerData = {
    serverData: ServerProps;
};

const Charities: React.FC<ServerData> = ({ serverData }) => {
    const { charities } = serverData;

    const data = useStaticQuery(graphql`
        {
            currentBuildDate {
                currentDate
            }
        }
    `);

    const schemaData = {
        '@context': 'https://schema.org',
        '@graph': [
            {
                '@type': 'Organization',
                '@id': 'https://www.joinripples.org/#organization',
                name: 'Ripples',
                url: 'https://www.joinripples.org/',
                sameAs: [],
                logo: {
                    '@type': 'ImageObject',
                    '@id': 'https://www.joinripples.org/#logo',
                    url: 'https://roundups-marketing-site.s3.eu-west-1.amazonaws.com/images/ripples-icon-white-bg.png',
                    width: 500,
                    height: 500,
                    caption: 'Ripples',
                },
                image: {
                    '@id': 'https://www.joinripples.org/#logo',
                },
            },
            {
                '@type': 'WebSite',
                '@id': 'https://www.joinripples.org/#website',
                url: 'https://www.joinripples.org/',
                name: 'Ripples',
                description: 'Donate pennies as you spend',
                publisher: {
                    '@id': 'https://www.joinripples.org/#organization',
                },
            },
            {
                '@type': 'WebPage',
                '@id': 'https://www.joinripples.org/charities/#webpage',
                url: 'https://www.joinripples.org/charities/',
                inLanguage: 'en-GB',
                name: 'Charities supported by Ripples | Ripples',
                isPartOf: {
                    '@id': 'https://www.joinripples.org/#website',
                },
                datePublished: '2020-05-01T16:12:32+00:00',
                dateModified: data.currentBuildDate.currentDate,
            },
        ],
    };

    return (
        <>
            <SEO
                title="Charities supported by Ripples"
                description="Ripples are proud to support a large array of charities across many sectors. This way we hope to make the largest impact possible from penny donations. View all charities using Ripples here"
                path="/charities/"
                schemaData={[schemaData]}
                keywords={undefined}
                image={undefined}
                redirect={undefined}
            />
            <Layout>
                <Section className={styles.root}>
                    <Container className={styles.container}>
                        <InfoBlock />
                        {charities === undefined ? (
                            <div className={styles.spinner}>
                                <Spinner />
                            </div>
                        ) : (
                            <CharitiesBlock
                                className={styles.charitiesBlock}
                                charities={charities}
                            />
                        )}
                    </Container>
                </Section>
            </Layout>
        </>
    );
};

export default Charities;
