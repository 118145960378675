import React, { FC } from 'react';

import * as styles from './ProgressBar.module.scss';

export interface ProgressBarProps {
    value: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ value }) => (
    <div className={styles.root}>
        <div
            className={styles.bar}
            style={{
                width: `${value}%`,
            }}
        />
    </div>
);

export default ProgressBar;
