import { Charity } from '../../types/global';

export const extractTagsFromCharities = (
    charities: Charity[],
): Charity['tags'] => {
    const tagsMap: {
        [id: Charity['tags'][number]['id']]: Charity['tags'][number];
    } = {};

    charities.forEach(({ tags }) => {
        tags.forEach((tag) => {
            if (!(tag.id in tagsMap)) {
                tagsMap[tag.id] = tag;
            }
        });
    });

    return Object.values(tagsMap);
};
