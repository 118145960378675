import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';

import Button from '../Button/Button';
import Typography from '../Typography/Typography';
import Stack from '../Stack/Stack';

import * as styles from './Chip.module.scss';

export interface ChipProps {
    label: ReactNode;
    selected?: boolean;
    onClick?: () => void;
}

const Chip: FC<ChipProps> = ({ label, selected, onClick }) => {
    const content = (
        <Stack
            className={classnames(styles.root, selected && styles.selected)}
            direction="row"
            spacing={8}
            valign="center">
            <Typography variant="bodySmall" className={styles.text}>
                {label}
            </Typography>
        </Stack>
    );

    if (onClick) {
        return (
            <Button variant="clear" onClick={onClick}>
                {content}
            </Button>
        );
    }

    return content;
};

export default Chip;
