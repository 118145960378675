import React, { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';

import * as styles from './Section.module.scss';

export interface SectionProps extends React.ComponentProps<'section'> {
    children?: ReactNode;
    label?: string;
    MobileBackgroundImage?: ReactElement;
    DesktopBackgroundImage?: ReactElement;
}

const Section = React.forwardRef<HTMLElement, SectionProps>(
    (
        {
            MobileBackgroundImage,
            DesktopBackgroundImage,
            label,
            className,
            children,
            ...restProps
        },
        ref,
    ) => (
        <section
            ref={ref}
            className={styles.root}
            role="contentinfo"
            aria-label={label}
            {...restProps}>
            <div className={classnames(styles.content, className)}>
                {children}
            </div>
            {MobileBackgroundImage &&
                React.cloneElement(MobileBackgroundImage, {
                    className: classnames(
                        MobileBackgroundImage.props.className,
                        styles.mobileBackgroundImage,
                    ),
                })}
            {DesktopBackgroundImage &&
                React.cloneElement(DesktopBackgroundImage, {
                    className: classnames(
                        DesktopBackgroundImage.props.className,
                        styles.desktopBackgroundImage,
                    ),
                })}
        </section>
    ),
);

export default Section;
