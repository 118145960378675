import React, { FC } from 'react';

import { pageRoutes } from '../../../../utils/constants';

import Link from '../../../UI/Link/Link';
import Typography from '../../../UI/Typography/Typography';
import Stack from '../../../UI/Stack/Stack';

const InfoBlock: FC = () => (
    <Stack fullWidth spacing={32}>
        <Typography variant="display" responsive align="center">
            Find a charity that matters to you
        </Typography>
        <Typography variant="leadParagraph" responsive align="center">
            Can’t see the charity you’re looking for?
        </Typography>
        <Typography variant="leadParagraph" responsive align="center">
            You can{' '}
            <Link href="mailto:hello@joinripples.org">suggest a charity</Link>{' '}
            or, if you work for them,{' '}
            <Link to={pageRoutes.forCharities}>add your charity now</Link>.
        </Typography>
    </Stack>
);

export default InfoBlock;
