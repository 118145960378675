import React, { FC, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import { extractTagsFromCharities } from '../../../../utils/extractTagsFromCharities';

import { Charity } from '../../../../../types/global';

// import Button from '../../../UI/Button/Button';
import SearchInput from '../../../UI/SearchInput/SearchInput';
import CharityCard from '../../../charity/CharityCard/CharityCard';
import CharityTags from '../../../charity/CharityTags/CharityTags';

import * as styles from './CharitiesBlock.module.scss';

export interface CharitiesBlockProps {
    charities: Charity[];
    className?: string;
}

const CharitiesBlock: FC<CharitiesBlockProps> = ({ charities, className }) => {
    const [numberOfShownCharities, setNumberOfShownCharities] = useState(1000);

    const [searchValue, setSearchValue] = useState('');
    const tags = useMemo(
        () =>
            [...extractTagsFromCharities(charities)].sort((a, b) =>
                a.label.localeCompare(b.label),
            ),
        [charities],
    );
    const [selectedTagsIds, setSelectedTagsIds] = useState<
        typeof tags[number]['id'][]
    >([]);

    const onTagClick = (clickedTag: typeof tags[number]) => {
        setSelectedTagsIds((oldSelectedTagsIds) => {
            if (selectedTagsIds.includes(clickedTag.id)) {
                return oldSelectedTagsIds.filter((id) => id !== clickedTag.id);
            }
            return [...oldSelectedTagsIds, clickedTag.id];
        });
    };

    const searchFilteredCharities = useMemo(
        () =>
            charities.filter((charity) =>
                searchValue.length === 0
                    ? true
                    : charity.name
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()),
            ),
        [charities, searchValue],
    );

    const tagsFilteredCharities = useMemo(
        () =>
            searchFilteredCharities.filter((charity) =>
                selectedTagsIds.length === 0
                    ? true
                    : charity.tags.length !== 0 &&
                      selectedTagsIds
                          .map((id) =>
                              charity.tags.reduce<boolean>(
                                  (hasSelectedTag, tag) => {
                                      if (hasSelectedTag) return hasSelectedTag;
                                      return tag.id === id;
                                  },
                                  false,
                              ),
                          )
                          .every(Boolean),
            ),
        [searchFilteredCharities, selectedTagsIds],
    );

    useEffect(() => {
        setNumberOfShownCharities(1000);
    }, [tagsFilteredCharities]);

    const charitiesMap = useMemo(
        () =>
            tagsFilteredCharities
                .slice(0, numberOfShownCharities)
                .map(
                    ({
                        id,
                        name,
                        pageRoute,
                        headline,
                        logo,
                        cover,
                        tags: charityTags,
                        campaign,
                    }) => (
                        <CharityCard
                            key={id}
                            name={name}
                            headline={headline}
                            logo={logo}
                            cover={cover}
                            pageRoute={pageRoute}
                            tags={charityTags}
                            campaign={campaign}
                        />
                    ),
                ),
        [tagsFilteredCharities, numberOfShownCharities],
    );

    // const loadMore = () => {
    //     setNumberOfShownCharities(
    //         (oldNumberOfShownCharities) => oldNumberOfShownCharities + 8,
    //     );
    // };

    // const isMaximumLoaded =
    //     numberOfShownCharities >= tagsFilteredCharities.length;

    return (
        <div className={classnames(className, styles.root)}>
            <div className={styles.tagsWrapper}>
                <CharityTags
                    tags={tags}
                    onTagClick={onTagClick}
                    selectedTagsIds={selectedTagsIds}
                />
            </div>
            <div className={styles.searchWrapper}>
                <SearchInput
                    value={searchValue}
                    onChange={setSearchValue}
                    placeholder="Search for a charity"
                />
            </div>
            <div className={styles.charitiesList}>{charitiesMap}</div>
            {/* {!isMaximumLoaded && (
                <Button
                    className={styles.loadMoreButton}
                    variant="link"
                    onClick={loadMore}>
                    Load more...
                </Button>
            )} */}
        </div>
    );
};

export default CharitiesBlock;
