import React, { FC, useEffect } from 'react';
import classnames from 'classnames';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import CookieBanner from '../CookieBanner/CookieBanner';

import '../../../css/layout.css';
import * as styles from './Layout.module.scss';

export interface LayoutProps {
    className?: string;
}

const Layout: FC<LayoutProps> = ({ children, className }) => {
    // INITIALIZE GOOGLE OPTIMIZE EXPERIMENT ON 'optimize.activate'
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.dataLayer.push({ event: 'optimize.activate' });
    }, []);

    return (
        <>
            <Header />
            <main className={classnames(styles.main, className)}>
                {children}
            </main>
            <Footer />
            <CookieBanner />
        </>
    );
};

export default Layout;
