import React, { FC } from 'react';

import { Charity } from '../../../../types/global';

import Chip from '../../UI/Chip/Chip';

import * as styles from './CharityTags.module.scss';

export interface CharityTagsProps {
    tags: Charity['tags'];
    onTagClick?: (clickedTag: Charity['tags'][number]) => void;
    selectedTagsIds?: Charity['tags'][number]['id'][];
}

const CharityTags: FC<CharityTagsProps> = ({
    tags,
    onTagClick,
    selectedTagsIds,
}) => {
    if (tags?.length === 0) return null;

    return (
        <div className={styles.root}>
            {tags?.map((tag) => {
                const { id, label } = tag;
                const handleClick =
                    onTagClick &&
                    (() => {
                        onTagClick(tag);
                    });
                const isSelected = selectedTagsIds
                    ? selectedTagsIds.includes(id)
                    : false;

                return (
                    <Chip
                        key={id}
                        label={label}
                        onClick={handleClick}
                        selected={isSelected}
                    />
                );
            })}
        </div>
    );
};

export default CharityTags;
