/** @jsx jsx */
import React, { ElementType, FC } from 'react';
import { jsx, css } from '@emotion/react';

export interface StackProps {
    spacing?: number;
    direction?: 'column' | 'row';
    align?: 'left' | 'center' | 'right' | 'normal';
    valign?: 'top' | 'center' | 'bottom' | 'normal';
    fullWidth?: boolean;
    className?: string;
    tag?: ElementType;
}

export enum FlexAlign {
    'left' = 'flex-start',
    'center' = 'center',
    'right' = 'flex-end',
    'top' = 'flex-start',
    'bottom' = 'flex-end',
    'normal' = 'normal',
}

const Stack: FC<StackProps> = ({
    spacing,
    direction = 'column',
    align = direction === 'row' ? 'left' : 'center',
    valign = 'top',
    fullWidth = false,
    className,
    children,
    tag = 'div',
}) => {
    const alignItems =
        direction === 'column' ? FlexAlign[align] : FlexAlign[valign];
    const justifyContent =
        direction === 'column' ? FlexAlign[valign] : FlexAlign[align];
    const spacingStyles = spacing
        ? {
              '> :first-of-type ~ *':
                  direction === 'column'
                      ? { marginTop: spacing }
                      : { marginLeft: spacing },
          }
        : {};

    const styles = css({
        display: 'flex',
        flexDirection: direction,
        ...(fullWidth ? { width: '100%' } : {}),
        alignItems,
        justifyContent,
        ...spacingStyles,
    });

    const Component = tag;

    return (
        <Component className={className} css={styles}>
            {children}
        </Component>
    );
};

export default Stack;
