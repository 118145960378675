import React, { FC } from 'react';

import CharityCampaign from '../CharityCampaign/CharityCampaign';
import Chip from '../../UI/Chip/Chip';
import Link from '../../UI/Link/Link';
import Typography from '../../UI/Typography/Typography';
import Stack from '../../UI/Stack/Stack';

import * as styles from './CharityCard.module.scss';

export interface CharityCardProps {
    name: string;
    headline: string;
    logo: {
        alt: string;
        src: string;
    };
    cover: {
        alt: string;
        src: string;
    };
    pageRoute: string;
    tags: {
        id: number;
        label: string;
    }[];
    campaign?: {
        title: string;
        money: {
            raised: number;
            goal: number;
        };
    };
}

const CharityCard: FC<CharityCardProps> = ({
    name,
    headline,
    logo,
    cover,
    pageRoute,
    tags,
    campaign,
}) => (
    <div className={styles.root}>
        <Stack spacing={8} align="left">
            <Link className={styles.image} to={pageRoute}>
                <img className={styles.cover} alt={cover.alt} src={cover.src} />
                <img
                    className={styles.logo}
                    alt={logo.alt}
                    src={logo.src}
                    width={56}
                    height={56}
                />
            </Link>
            <Link className={styles.nameLink} to={pageRoute}>
                <Typography
                    className={styles.name}
                    variant="h2"
                    fontWeight="semibold">
                    {name}
                </Typography>
            </Link>
            <Stack spacing={16} align="left" fullWidth>
                <Typography variant="bodySmall" paragraph>
                    {name} is {headline}
                    {'. '}
                    <Link to={pageRoute}>Find out more</Link>
                </Typography>
                {campaign && (
                    <CharityCampaign
                        title={campaign.title}
                        money={campaign.money}
                    />
                )}
                {/* <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                    }}> */}
                {tags.map(({ id, label }) => (
                    <Chip key={id} label={label} />
                ))}
                {/* </div> */}
            </Stack>
        </Stack>
    </div>
);

export default CharityCard;
